/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.device-container{
  margin: 20px 0;
  width: 100%;
  max-width: 400px;
}

.wifi-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
  max-width: 400px;
}

.wifi-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f1f1f1;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.wifi-item.selected {
  background-color: #e0f7fa;
}

.wifi-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.wifi-first{
  display: flex;
  align-items: center;
}

.wifi-name {
  margin-left: 10px;
  flex-grow: 1;
}

.password-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  position: relative;
}

.password-container input {
  padding: 5px;
  width: 100%; /* Adjust input width to make space for the icon */
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 24px;
}

.eye-icon {
  position: absolute;
  right: 15px;
  top: 29%; /* Adjusted to be slightly below */
  cursor: pointer;
}

.connect-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.connect-button:hover {
  background-color: #45a049;
}

#btn-1{
  background-color: #f007b6;
  color: white;
  margin-top: 10px;
  
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #6d6c6a;
  }
  &:active {  
    transform: translateY(5px);
    transition: transform 0.3s ease-in-out;
  }
}
#btn-2{
  background-color: #4031b3;
  color: white;
  margin-top: 10px;
  
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #6d6c6a;
  }
  &:active {  
    transform: translateY(5px);
    transition: transform 0.3s ease-in-out;
  }
}
#btn-3{
  background-color: #f83f11;
  color: white;
  margin-top: 10px;
  
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #6d6c6a;
  }
  &:active {  
    transform: translateY(5px);
    transition: transform 0.3s ease-in-out;
  }
}

